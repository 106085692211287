<template>
  <div class="page-layout about-page wave-filigrana" v-if="pageData && pageData.attributes">

    <HeaderIntroImage underlined :headerMetadata="pageData"></HeaderIntroImage>
    
    <div class="container " v-if="pageData.attributes.field_page_main_content">
      <div class="page-body blue-title" v-html="this.pageData.attributes.field_page_main_content.value"></div>

      <div v-if="pageData.field_attachments.length">
        <!-- <p class="label">ATTACHMENTS</p> -->
        <h4 class="blue">Attachments</h4>
        <a v-for="(att, i) in pageData.field_attachments" :key="'att'+i"
          class="btn download-link slim bold plain" :href="endpoint + att.attributes.uri.url" download
          target="_blank">
          <span class="download icon-right" v-if="pageData.relationships.field_attachments.data[i].meta.description != '' ">
            {{pageData.relationships.field_attachments.data[i].meta.description}}
          </span>

          <span v-else class="download icon-right">{{ att.attributes.filename }}</span>
        </a>

        <br>
        <br>
      </div>

    </div>

    <BlockWithImage v-if="pageData.field_ref_page_content" :imageurl="blockimg">
      <h2 class="blue">{{ this.pageData.field_ref_page_content[0].attributes.field_titolo.value }}</h2>
      <div class="list-1" v-html="this.pageData.field_ref_page_content[0].attributes.field_text_with_summary.value"></div>
      <button @click="goto()" class="btn"><span>GET INVOLVED</span></button>
    </BlockWithImage>

  </div>
</template>

<script>
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import BlockWithImage from '../components/layout-blocks/BlockWithImage.vue'
import { fetchSinglePage } from '../libs/drupalClient'
export default {
  name: 'about',
  components: {
    HeaderIntroImage,
    BlockWithImage
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
          title: "Why Qtedu Csa",
          tag: "",
          subtitle: ""
        }
      },
      currentPageID: "b0bfaac4-7617-4805-a648-35955235bec9"
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    blockimg() {
      return require("../assets/block_img_1.jpg")
    },
    endpoint() {
      return process.env.VUE_APP_ENDPOINT
    }
  },
  methods: {
    goto() {
      this.$router.push('/get-involved')
    }
  },
  mounted() {
    fetchSinglePage(this.currentPageID, {
      include: ['field_ref_page_content', 'field_ref_image', 'field_attachments']
    })
    .then(res => {
      this.pageData = res
     //console.log(this.pageData)
    })
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.about-page {
  padding-bottom: 20vh;
  position: relative;

  
  
}
</style>